body {
  background-color: #fff0cb;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Style for the navigation bar */
nav {
  text-align: center; /* Center the navigation menu */
}

/* Style for the unordered list within the nav */
nav ul {
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
  display: inline-block; /* Align items horizontally */
}

/* Style for each list item */
nav ul li {
  display: inline; /* Display list items in a row */
  margin-right: 20px; /* Add some space between menu items */
}

/* Style for links */
nav ul li a {
  text-decoration: none; /* Remove underline from links */
  color: #333; /* Set link color */
  padding: 10px 15px; /* Add padding for a larger clickable area */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}

/* Hover effect for links */
nav ul li a:hover {
  background-color: #f2f2f2; /* Change background color on hover */
  color: #007bff; /* Change text color on hover */
}